import {useMemo, useState, useEffect} from 'react'
import {get, omit, uniq} from 'lodash'
import {ErrorMessage} from 'formik'
import TagsInput from 'react-tagsinput'

// import 'react-tagsinput/react-tagsinput.css'
import './index.scss'

let timer = 0

const Input = ({
  id,
  label,
  type,
  placeholder,
  helpText = '',
  required,
  field,
  disabled,
  maxLength = 50,
  form: {touched, errors, setFieldValue, submitForm, isSubmitting},
  ...props
}) => {
  const [tags, setTags] = useState([])
  useEffect(() => {
    field.value && setTags(field.value)
  }, [field.value])

  const formValue = useMemo(() => {
    if (typeof field.value === 'string') {
      return field.value.join(',')
    }
    return field.value
  }, [field.value])

  const renderTag = (props) => {
    let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {!disabled && (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            className={classNameRemove}
            onClick={() => {
              const _tags = tags.filter((_, k) => k !== key)
              setTags(_tags)
              setFieldValue(field.name, _tags)
            }}
          />
        )}
      </span>
    )
  }

  const renderInput = (props) => {
    let {onChange, value, addTag, ...other} = props
    return <textarea onChange={onChange} value={value} rows={10} {...other} />
  }

  return (
    <>
      <TagsInput
        {...omit(props, ['hasSlug', 'submitWhenChange'])}
        type={type}
        id={id}
        placeholder={placeholder}
        name={field.name}
        value={formValue}
        disabled={disabled}
        renderInput={renderInput}
        renderTag={renderTag}
        addKeys={[9]}
        onChange={(evt, value, index) => {
          const _value = get(value, '[0]', '')
          const _split_value = _value
            .split(/,|\n/)
            .map((v) => v.trim())
            .filter((v) => !!v)
          evt.pop()
          const values = uniq([...evt, ..._split_value])

          if(values.length <= maxLength) {
            setTags(values)
            setFieldValue(field.name, values)
          }

          // check auto submit when form change
          if (get(props, 'submitWhenChange', false)) {
            clearTimeout(timer)
            timer = setTimeout(submitForm, 1000)
          }
        }}
        className={`tags-input ${props.className} ${
          get(touched, field.name) && get(errors, field.name) ? `is-invalid` : ''
        }`}
        preventSubmit={false}
      />
      {formValue.length > 0 && formValue.length <= 50 && (
        <div className='form-text text-warning'>
          For optimal performance with Rankster’s AI engine, we recommend using more than 50 keywords. <br/> 
          Using fewer than 50 keywords may not provide enough data for a comprehensive analysis of your site.
        </div>
      )}
      {!!helpText && (
        <div className='form-text text-info' dangerouslySetInnerHTML={{__html: helpText}} />
      )}
      {formValue.length > 0 && <div className='form-text'>{formValue.length} keywords</div>}
      <ErrorMessage component='div' name={field.name} className='invalid-feedback' />
    </>
  )
}

export default Input
