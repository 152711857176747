/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { connect } from "react-redux";
import { compose } from "redux";
import { get } from "lodash";
import {Link, useParams} from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';

import { PROJECT_URL } from "Constants/routerConstants";
import { STATUS_CODE } from "Constants/statusCodeApi";
import { AlertError } from "Utils/alertUtils";
import { Spinner } from 'Components';
import { useVisualizationData } from 'Hooks/useVisualizationData';

import { RanksterScore } from './components/RanksterScore';
import { MarketShare } from './components/MarketShare';
import { KeywordTracking } from './components/KeywordTracking';
import { Keyword } from './components/Keyword';
import { ContentGaps } from './components/ContentGaps';
import { CompetitionMap } from './components/CompetitionMap';
import { Recommendations } from './components/Recommendations';
import { DomainPerformanceChart } from './components/DomainPerformanceChart';
import { ExpectedImprovements } from './components/ExpectedImprovements';

import './index.scss';

const ProjectView = (props) => {
  const params = useParams();
  const paramId = get(params, 'id');
  const projectId = paramId || get(props, 'projectId');

  const [pages, setPages] = useState({
    rankster_score: 0,
    market_share: 0,
    keyword_tracking: 0,
    top_ranking_keyword_stats: 0,
    content_gaps: 0,
    competition_maps: 0,
    recommendations: 0,
    ranking_gain_data: 0,
  });

  const { isLoading, error, getData } = useVisualizationData(projectId);

  if (isLoading) {
    return <div><Spinner/> Loading...</div>;
  }

  if (error) {
    AlertError('Server Error');
    return null;
  }

  const renderFloatingUpgradeButton = () => {
    return (
      <div className="floating-upgrade-button">
        <Link to={`${PROJECT_URL}/${projectId}/2`} className="btn btn-warning btn-lg shadow-sm">
          <i className="bi bi-arrow-up-circle-fill me-2"></i>
          Unlock All Recommendations
        </Link>
      </div>
    );
  };

  const renderTrialNotification = () => {
    return (
      <div className="card card-custom bg-primary mb-8">
        <div className="card-body py-7 px-7">
          <div className="d-flex align-items-center">
            <div className="d-flex flex-column flex-grow-1 py-2">
              <h2 className="text-white fw-bolder mb-3">Unlock the Full Power of Rankster</h2>
              <p className="text-white fw-bold fs-4 mb-3">
                You're currently on a trial plan with limited access to our AI-powered recommendations.
              </p>
              <p className="text-white opacity-75 mb-4">
                Upgrade now to unlock <span className="fw-bolder">all recommendations</span> - uniquely tailored to your site based on millions of data points analyzed by our AI engine. These insights are your fastest path to improved SEO performance.
              </p>
              <div className="d-flex">
                <Link to={`${PROJECT_URL}/${projectId}/2`} className="btn btn-warning fw-bold px-6 py-3">
                  Upgrade Now
                </Link>
                <span className="text-white opacity-75 align-self-center ms-4">
                  <i className="bi bi-lock-fill fs-2 me-2 text-warning"></i> Unlock all recommendations
                </span>
              </div>
            </div>
            <div className="d-none d-lg-flex flex-column ms-10">
              <div className="d-flex align-items-center mb-3">
                <div className="symbol symbol-40px me-3">
                  <div className="symbol-label bg-white">
                    <i className="bi bi-graph-up-arrow fs-1 text-primary"></i>
                  </div>
                </div>
                <div className="text-white">
                  <div className="fs-5 fw-bolder">Improved Rankings</div>
                  <div className="fs-7 opacity-75">Climb search results faster</div>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="symbol symbol-40px me-3">
                  <div className="symbol-label bg-white">
                    <i className="bi bi-lightbulb fs-1 text-primary"></i>
                  </div>
                </div>
                <div className="text-white">
                  <div className="fs-5 fw-bolder">AI-Powered Insights</div>
                  <div className="fs-7 opacity-75">Data-driven recommendations</div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-40px me-3">
                  <div className="symbol-label bg-white">
                    <i className="bi bi-bar-chart-line fs-1 text-primary"></i>
                  </div>
                </div>
                <div className="text-white">
                  <div className="fs-5 fw-bolder">Tailored Strategy</div>
                  <div className="fs-7 opacity-75">Unique to your site & industry</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderTooltip = (title, content) => (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip>
          <div className="tooltip-content p-2">
            <strong className="d-block mb-2">{title}</strong>
            <p className="mb-0">{content}</p>
          </div>
        </Tooltip>
      }
    >
      <span className="ms-2 d-inline-flex align-items-center">
        <i className="bi bi-info-circle fs-4 text-gray-500"></i>
      </span>
    </OverlayTrigger>
  );

  const renderContent = () => {
    const rankster_scores = getData('visualisation_data.rankster_scores');
    const market_share_impressions = getData('visualisation_data.market_share_impressions');
    const keyword_tracking = getData('visualisation_data.keyword_tracking');
    const top_ranking_keyword_stats = getData('visualisation_data.top_ranking_keyword_stats');
    const content_gaps = getData('visualisation_data.content_gaps');
    const competition_maps = getData('visualisation_data.competition_maps');
    const recommendations = getData('visualisation_data.recommendations');
    const ranking_gain_data = getData('visualisation_data.ranking_gain_data');
    const is_trial = getData('last_payment.payment_item_obj.is_trial') || false;
    const project_id = projectId;

    const competition_maps_key_param = pages.competition_maps > 0 ? `/${pages.competition_maps}` : '';

    return (
      <div className="project-report">
        {is_trial && renderTrialNotification()}
        {is_trial && renderFloatingUpgradeButton()}

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4'>
            <div className='row g-5 g-xl-8 h-100 mb-5 mb-xl-8'>
              <div className='col-xl-12'>
                <MarketShare
                  className='card-xl-stretch h-100'
                  chartColor='primary'
                  chartHeight='150px'
                  setPages={setPages}
                  pages={pages}
                  market_share_impressions={market_share_impressions}
                  projectId={project_id}
                  getData={getData}
                  renderTooltip={renderTooltip}
                />
              </div>
            </div>
          </div>

          <div className='col-xl-8 mb-5 mb-xl-8'>
            <KeywordTracking
              className='card-xl-stretch h-100'
              keyword_tracking={keyword_tracking}
              setPages={setPages}
              pages={pages}
              projectId={project_id}
              getData={getData}
              renderTooltip={renderTooltip}
            />
          </div>
        </div>

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-8'>
            <Recommendations
              className='card-xl-stretch h-100'
              projectId={project_id}
              recommendations={recommendations}
              setPages={setPages}
              pages={pages}
              is_trial={is_trial}
              getData={getData}
              renderTooltip={renderTooltip}
            />
          </div>

          <div className='col-xl-8'>
            <div className='row g-5 g-xl-8 h-100 mb-5 mb-xl-8'>
              <div className='col-xl-5'>
                <Keyword
                  className='card-xl-stretch h-100'
                  chartColor='primary'
                  chartHeight='150px'
                  setPages={setPages}
                  pages={pages}
                  rankster_scores={rankster_scores}
                  top_ranking_keyword_stats={top_ranking_keyword_stats}
                  getData={getData}
                  renderTooltip={renderTooltip}
                />
              </div>

              <div className='col-xl-7'>
                <ContentGaps 
                  className='card-xl-stretch h-100' 
                  content_gaps={content_gaps} 
                  setPages={setPages} 
                  pages={pages} 
                  projectId={project_id}
                  getData={getData}
                  renderTooltip={renderTooltip}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-12 mb-5 mb-xl-8'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title d-flex align-items-center'>
                  <span className='card-label fw-bolder fs-3'>Competition Map</span>
                  {renderTooltip(
                    "Competition Map",
                    "This visualization compares your site's search performance against competitors. The vertical axis shows the percentage of project keywords a site ranks for, the horizontal axis shows the average search ranking position, and bubble size represents total search volume."
                  )}
                </h3>
              </div>

              <div className='card-body d-flex flex-column pt-0'>
                <DomainPerformanceChart
                  domainchart={get(competition_maps, `[${pages.competition_maps}].results`, [])}
                  projectDetail={{
                    website: getData('website'),
                    name: getData('name')
                  }}
                  getData={getData}
                />
                <div className='pt-5'>
                  <Link 
                    className={`btn btn-primary w-100 py-3 ${get(competition_maps, `[${pages.competition_maps}].results`, []).length === 0 ? 'pe-none' : ''}`} 
                    to={`${PROJECT_URL}/report/${project_id}/competition-map${competition_maps_key_param}`}
                  >
                    Table View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {ranking_gain_data &&
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-12 mb-5 mb-xl-8'>
              <div className={`card card-xl-stretch mb-xl-8`}>
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title d-flex align-items-center'>
                    <span className='card-label fw-bolder fs-3'>Expected Improvements</span>
                    {renderTooltip(
                      "Expected Improvements",
                      "This chart estimates the average increase in search rankings if you implement Rankster's high-impact recommendations. Small changes today can lead to big ranking gains and greater traffic."
                    )}
                  </h3>
                </div>
                <div className='card-body d-flex flex-column pt-0'>
                  <ExpectedImprovements
                    ranking_gain_data={get(ranking_gain_data, `[${pages.ranking_gain_data}].results`, [])}
                    projectDetail={getData('')}
                    getData={getData}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  return renderContent();
}

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

const withCompose = compose(connect(mapStateToProps));

export default withCompose(ProjectView);