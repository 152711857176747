/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {get, orderBy} from 'lodash'
// import {KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {Link} from 'react-router-dom'
import {PROJECT_URL} from 'Constants/routerConstants'
import {RECOMMENDATIONS__LINK__GLOBAL} from 'Constants/global'
import 'react-tooltip/dist/react-tooltip.css'
import {Tooltip} from 'react-tooltip'

const Recommendations = ({className, ...props}) => {
  const projectId = get(props, 'projectId')
  const recommendations_key = get(props, 'page.recommendations', 0)
  const key_param = recommendations_key > 0 ? `/${recommendations_key}` : ''

  const current_recommendations = get(
    props,
    `recommendations[${recommendations_key}].results`,
    []
  ).filter((v) => v.link === RECOMMENDATIONS__LINK__GLOBAL)

  const recommendations = orderBy(current_recommendations, [(c) => c.value], ['desc']).slice(0, 5)
  const is_trial = get(props, 'is_trial', false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Recommendations</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 d-flex flex-column justify-content-between pt-0 pb-0'>
        <ul
          className='ps-5'
          style={{listStyle: 'decimal'}}
          onMouseLeave={() => setIsOpen(undefined)}
        >
          {recommendations.map((item, key) => (
            <li
              key={key}
            >
              {/* begin::Item */}
              <div className='d-flex align-items-center mb-5'>
                {/* begin::Description */}
                <div className='flex-grow-1'>
                  <span className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                    {item.title}
                    <span className='badge badge-light-primary fs-8 fw-bolder ms-3'>
                      {item.seo_feature_type}
                    </span>
                  </span>
                  <span className='text-muted d-block'>
                    {/* {item.description !== '0' ? item.description : ''} */}
                    {/* Image formats like WebP and AVIF often provide better compression than PNG or JPEG, which means faster downloads and less data consumption. <a href="#">Learn more.</a> */}
                  </span>
                </div>
                {/* end::Description */}
              </div>
              {/* end:Item */}
            </li>
          ))}
          {is_trial &&
            Array.from({length: 9}).map((data, index) => (
              <>
                <Tooltip isOpen={isOpen === index} anchorSelect={`#item-${index}`} clickable>
                  <Link to={`${PROJECT_URL}/${projectId}/2`} className='tooltip-content cursor-pointer'>
                    <div className='tooltip-title' style={{color: '#fff'}}>Upgrade to a paid plan</div>
                    <div className='tooltip-desc'style={{color: '#fff'}}>
                      Unlock full access to all recommendations by upgrading to a paid plan today!
                    </div>
                  </Link>
                </Tooltip>
                <li
                  className='mb-1'
                  id={`item-${index}`}
                  onMouseEnter={() => setIsOpen((prev) => (!(prev === index + 1) ? index : prev))}
                  onClick={() => setIsOpen(undefined)}
                  key={`placeholder-${index}`}
                  style={{
                    filter: 'blur(3px)',
                    listStyle: 'none',
                  }}
                >
                  {/* begin::Item */}
                  <div className='d-flex align-items-center mb-5'>
                    {/* begin::Description */}
                    <div className='flex-grow-1'>
                      <span className='text-gray-800 fw-bolder fs-6'>
                        You’re currently viewing one recommendation. Unlock full access by upgrading
                        to a paid plan today!
                        <span className='badge badge-light-primary fs-8 fw-bolder ms-3'>
                          Content
                        </span>
                      </span>
                    </div>
                    {/* end::Description */}
                  </div>
                  {/* end:Item */}
                </li>
              </>
            ))}
        </ul>
        {recommendations.length === 0 && <div className="text-muted text-center">Recommendations is Empty</div>}

        <div className="pb-8">
          {/* <button 
            className={`btn btn-primary w-100 py-3`} 
            disabled={true}
            // onClick={handleLearnMore} 
            // disabled={props.pages.keyword_tracking >= (props.keyword_tracking.length - 1)}
          >Learn more</button> */}
          <Link className={`btn btn-primary w-100 py-3 ${recommendations.length === 0 ? 'pe-none' : ''}`} to={{ pathname: `${PROJECT_URL}/report/${projectId}/recommendations${key_param}`}}>Learn more</Link>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Recommendations}
