/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { compose } from "redux";
import { get, round } from "lodash";
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { PROJECT__ADD_URL, PROJECT_URL } from "Constants/routerConstants";
import { getMyProjectRequestAction, toggleMyProjectModalAction } from "Store/Actions/projectAction";
import { selectDefaultMyProjectApi, updateProjectApi, setAdminProjectApi, getReportProjectApi } from "Apis/projectApi";
import { PROJECT__FILTER_TYPES } from "Constants";
import { STATUS_CODE } from "Constants/statusCodeApi";
import { AlertError, AlertSuccess } from "Utils/alertUtils";
import { getUserProfileRequestAction } from 'Store/Actions/userAction';
import { Spinner } from 'Components';

import {
  // CompetitionMap,
  DomainPerformanceChart,
  RanksterScore,
  MarketShare,
  KeywordTracking,
  Keyword,
  ContentGaps,
  Recommendations,
  ExpectedImprovements,
} from './components'

import './index.scss'


const ProjectView = (props) => {
  const user = get(props, 'profile')
  const params = useParams();
  const paramId = get(params, 'id');
  const projectId = get(props, 'projectId');

  const [projectDetail, setProjectDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState({
    rankster_score: 0,
    market_share: 0,
    keyword_tracking: 0,
    top_ranking_keyword_stats: 0,
    content_gaps: 0,
    competition_maps: 0,
    recommendations: 0,
    ranking_gain_data: 0,
  });


  useEffect(() => {
    paramId && getProjectDetail(paramId)
  }, [paramId])

  useEffect(() => {
    projectId && getProjectDetail(projectId)
  }, [projectId])

  const getProjectDetail = (id) => {
    setLoading(true);
    getReportProjectApi(id).then(resp => {
      if (resp) {
        if (resp.status === STATUS_CODE.HTTP_200_OK) {
          setProjectDetail(resp.data)
          // const top_ranking_keyword_stats = get(resp.data, 'visualisation_data.top_ranking_keyword_stats', [])
          // const market_share_impressions = get(resp.data, 'visualisation_data.market_share_impressions', [])
          // const keyword_tracking = get(resp.data, 'visualisation_data.keyword_tracking', [])
          // const ranking_gain_data = get(resp.data, 'visualisation_data.ranking_gain_data', [])
          const competition_maps_latest_date = get(resp.data, 'visualisation_data.competition_maps[0].datetime', '')
          const setLatestRun = get(props, 'setLatestRun');
          !!setLatestRun && setLatestRun(competition_maps_latest_date)
          // setPages({
          //   ...pages,
          //   // top_ranking_keyword_stats: top_ranking_keyword_stats.length - 1,
          //   // market_share: market_share_impressions.length - 1,
          //   // keyword_tracking: keyword_tracking.length - 1,
          // })
        }
      } else {
        AlertError('Server Error');
      }
      setLoading(false)
    }).catch(error => {
      setLoading(false);
      AlertError('Server Error');
    });
  }

  const renderTrialNotification = () => {
    const project_id = paramId || projectId;
    return (
      <div className='alert alert-warning' role='alert'>
        You’re currently on a trial account, which allows access to one recommendation. Upgrade to a
        paid account to unlock multiple actionable insights for each landing page. <Link to={`${PROJECT_URL}/${project_id}/2`}>Upgrade Now!</Link>
      </div>
    )
  }

  const renderContent = () => {
    const rankster_scores = get(projectDetail, 'visualisation_data.rankster_scores')
    const market_share_impressions = get(projectDetail, 'visualisation_data.market_share_impressions')
    const keyword_tracking = get(projectDetail, 'visualisation_data.keyword_tracking')
    const top_ranking_keyword_stats = get(projectDetail, 'visualisation_data.top_ranking_keyword_stats')
    const content_gaps = get(projectDetail, 'visualisation_data.content_gaps')
    const competition_maps = get(projectDetail, 'visualisation_data.competition_maps')
    const recommendations = get(projectDetail, 'visualisation_data.recommendations')
    const ranking_gain_data = get(projectDetail, 'visualisation_data.ranking_gain_data')
    const project_id = paramId || projectId;
    // const api_project_detail = get(projectDetail, 'api_project');

    const is_trial = get(projectDetail, 'last_payment.payment_item_obj.is_trial', false)

    const competition_maps_key_param = pages.competition_maps > 0 ? `/${pages.competition_maps}` : ''
    return (
      <div className="project-report">

        {is_trial && renderTrialNotification()}

        {/* begin::Row */}
        <div className='row g-5 g-xl-8'>
          {/* begin::Col */}
          <div className='col-xl-4'>
            {/* begin::Row */}
            <div className='row g-5 g-xl-8 h-100 mb-5 mb-xl-8'>

              {/* begin::Col */}
              {/* <div className='col-xl-6'>
                <RanksterScore
                  className='card-xl-stretch h-100'
                  chartColor='primary'
                  chartHeight='150px'
                  setPages={setPages}
                  pages={pages}
                  rankster_scores={rankster_scores}
                  projectId={project_id}
                />
              </div> */}
              {/* end::Col */}

              {/* begin::Col */}
              <div className='col-xl-12'>
                <MarketShare
                  className='card-xl-stretch h-100'
                  chartColor='primary'
                  chartHeight='150px'
                  setPages={setPages}
                  pages={pages}
                  market_share_impressions={market_share_impressions}
                  projectId={project_id}
                />
              </div>
              {/* end::Col */}
            </div>
            {/* end::Row */}
          </div>
          {/* end::Col */}

          {/* begin::Col */}
          <div className='col-xl-8 mb-5 mb-xl-8'>
            <KeywordTracking
              className='card-xl-stretch h-100'
              keyword_tracking={keyword_tracking}
              setPages={setPages}
              pages={pages}
              projectId={project_id}
            />
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row g-5 g-xl-8'>
          {/* begin::Col */}
          <div className='col-xl-4 mb-5 mb-xl-8'>
            <Recommendations
              className='card-xl-stretch h-100'
              projectId={project_id}
              recommendations={recommendations}
              setPages={setPages}
              pages={pages}
              is_trial={is_trial}
            />
          </div>
          {/* end::Col */}

          {/* begin::Col */}
          <div className='col-xl-8'>
            {/* begin::Row */}
            <div className='row g-5 g-xl-8 h-100 mb-5 mb-xl-8'>

              {/* begin::Col */}
              <div className='col-xl-5'>
                <Keyword
                  className='card-xl-stretch h-100'
                  chartColor='primary'
                  chartHeight='150px'
                  setPages={setPages}
                  pages={pages}
                  rankster_scores={rankster_scores}
                  top_ranking_keyword_stats={top_ranking_keyword_stats}
                />
              </div>
              {/* end::Col */}

              {/* begin::Col */}
              <div className='col-xl-7'>
                <ContentGaps className='card-xl-stretch h-100' content_gaps={content_gaps} setPages={setPages} pages={pages} projectId={project_id} />
              </div>
              {/* end::Col */}
            </div>
            {/* end::Row */}
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row g-5 g-xl-8'>
          {/* begin::Col */}
          <div className='col-xl-12 mb-5 mb-xl-8'>

            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Competition Map</span>
                  {/* <span className='text-muted fw-bold fs-7'>The following chart shows how Rankster Scores tend to relate to search engine positions.</span> */}
                </h3>
              </div>
              {/* end::Header */}

              {/* begin::Body */}
              <div className='card-body d-flex flex-column pt-0'>
                <DomainPerformanceChart
                  domainchart={get(competition_maps, `[${pages.competition_maps}].results`, [])}
                  projectDetail={projectDetail}
                />
                <div className='pt-5'>
                  <Link className={`btn btn-primary w-100 py-3 ${get(competition_maps, `[${pages.competition_maps}].results`, []).length === 0 ? 'pe-none' : ''}`} to={`${PROJECT_URL}/report/${project_id}/competition-map${competition_maps_key_param}`}>
                    {/* Learn more */}
                    Table View
                  </Link>
                </div>
              </div>
              {/* end::Body */}
            </div>

            {/* {
              competition_maps && 
                <CompetitionMap 
                  setPages={setPages}
                  pages={pages}
                  competition_maps={competition_maps} 
                  api_project_detail={api_project_detail}
                  projectId={project_id}
                  className='card-xl-stretch mb-xl-8' 
                />
            } */}
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        {
          ranking_gain_data &&
            <div className='row g-5 g-xl-8'>
              <div className='col-xl-12 mb-5 mb-xl-8'>
                <div className={`card card-xl-stretch mb-xl-8`}>
                  {/* begin::Header */}
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>Expected Improvements</span>
                    </h3>
                  </div>
                  <div className='card-body d-flex flex-column pt-0'>
                    <ExpectedImprovements
                      ranking_gain_data={get(ranking_gain_data, `[${pages.ranking_gain_data}].results`, [])}
                      projectDetail={projectDetail}
                    />
                    {/* <div className='pt-5'>
                      <Link className={`btn btn-primary w-100 py-3 ${get(ranking_gain_data, `[${pages.ranking_gain_data}].results`, []).length === 0 ? 'pe-none' : ''}`} to={`${PROJECT_URL}/report/${project_id}/competition-map${competition_maps_key_param}`}>
                        Table View
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
        }
        {/* end::Row */}


        {/* <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">Domain performance</span>
              </h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className="text-muted mb-5 fw-bold fs-7">These bubble charts show how different domains perform in terms of average search rank, Rankster Score and market penetration. ‘Strong visibility’ means the domain ranks for more than 20% of search terms and is in the top 50% of Rankster Scores. These domains capture a lot of search traffic and are likely to continue doing so. ‘Emerging visibility’ means the domain ranks for less than 20% of search terms, but is in the top 50% of Rankster Scores. The search ranking and traffic to these domains are likely to increase in time. ‘Diminishing visibility’ means the domain ranks for more than 20% of search terms, but is in the bottom 50% of Rankster Scores. The search ranking and traffic to these domains are likely to decrease in time. ‘Poor visibility’ means the domain ranks for less than 20% of search terms and is in the bottom 50% of Rankster Scores. These domains do not capture much of the search traffic and are unlikely to gain much in time without a significant overhaul.</div>
            {
              (get(projectDetail, 'api_datas.domainchart') && get(projectDetail, 'api_datas.run_detail')) &&
                <DomainPerformanceChart 
                  domainchart={get(projectDetail, 'api_datas.domainchart')} 
                  run_detail={get(projectDetail, 'api_datas.run_detail')} 
                />
            }
          </div>
        </div> */}
        {/*         
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">Recommendations table</span>
              </h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted text-center'>
                    <th className='min-w-150px'>Title</th>
                    <th className='min-w-150px'>Domain</th>
                    <th className='min-w-150px'>Feature</th>
                    <th className='min-w-150px'>Value</th>
                    <th className='min-w-150px'>Documentation</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    get(projectDetail, 'api_datas.recommendations', []).map((value, key) => {
                      return key < 20 && (
                        <tr className="text-dark fw-bolder" key={key}>
                          <td>
                            {value.title}
                            <span className="text-muted fw-bold text-muted d-block fs-7">{value.description}</span>
                          </td>
                          <td>{value.domain}</td>
                          <td>
                            {value.feature}
                            <span className="text-muted fw-bold text-muted d-block fs-7">{value.featuretype}</span>
                          </td>
                          <td className="text-center">{value.value}</td>
                          <td className="text-center">
                            <a href={value.documentation} target="_blank" rel="noreferrer" className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2">View</a>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div> */}


      </div>
    )
  }

  return (
    <>
      {(!loading && projectDetail) ? renderContent() : <div><Spinner /> Loading...</div>}
    </>
  )
}


const mapStateToProps = (state) => ({
  // state: state,
  isAuthenticated: state.authentication.isAuthenticated,
  //
  profile: state.user.profile,
  myProjects: get(state, "myProjectsReducer"),
});

const mapDispatchToProps = (dispatch) => ({
  getMyProjectRequestAction: (query) => {
    dispatch(getMyProjectRequestAction(query));
  },
  getUserProfileRequestAction: () => {
    dispatch(getUserProfileRequestAction());
  },
  toggleMyProjectModalAction: (data) => {
    dispatch(toggleMyProjectModalAction(data));
  },
});

const withCompose = compose(connect(mapStateToProps, mapDispatchToProps));


export default withCompose(ProjectView)